<template>
  <v-container fluid>
    <v-card flat outlined class="pa-4 pa-sm-6">
      <v-sheet
        class="mb-5 text-h6 d-flex align-center flex-wrap"
        color="transparent"
      >
        <v-btn icon class="me-2" @click="$router.go(-1)">
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        Leave Application - {{ application.id }}
        <v-spacer></v-spacer>
        <v-sheet color="transparent">
          <v-chip
            v-if="!loading"
            small
            dark
            class="me-2"
            :color="application.staff_uuid == auth.uuid ? 'blue' : 'lime'"
            >{{
              application.staff_uuid == auth.uuid
                ? "My Application"
                : "Other's Application"
            }}</v-chip
          >

          <v-chip
            v-if="!loading"
            small
            dark
            :color="
              application.review_status == 'APPROVED'
                ? 'green'
                : application.review_status == 'REJECTED'
                ? 'error'
                : 'amber darken-2'
            "
            >{{
              application.review_status == "APPROVED"
                ? "Approved"
                : application.review_status == "REJECTED"
                ? "Rejected"
                : "Pending"
            }}</v-chip
          >
        </v-sheet>
      </v-sheet>
      <v-row class="ms-md-12" v-if="loading">
        <v-col cols="12" sm="6" md="4" v-for="i in 15" :key="i">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-sheet v-else-if="application" color="transparent">
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-blank-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  application.from_date
                }}</v-list-item-title>
                <v-list-item-subtitle>From (Date)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-blank-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ application.to_date }}
                </v-list-item-title>
                <v-list-item-subtitle>To (Date)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-tally-mark-5 </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ application.no_of_days }}
                </v-list-item-title>
                <v-list-item-subtitle>No of days</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-account-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="application.staff">
                  {{
                    application.staff.first_name +
                    " " +
                    application.staff.last_name
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>Staff</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-shape-plus-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ application.type }}
                </v-list-item-title>
                <v-list-item-subtitle>Leave Type</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-office-building-marker-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ application.department }}
                </v-list-item-title>
                <v-list-item-subtitle>Department/Location</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12">
            <v-sheet class="px-4" color="transparent">
              <v-sheet class="text-caption" color="transparent">Reason</v-sheet>
              <p class="text--primary">
                {{ application.reason }}
              </p>
            </v-sheet>
          </v-col>
          <v-col cols="12" v-if="application.late_reason">
            <v-sheet class="px-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Retroactive Leave Reason</v-sheet
              >
              <p class="text--primary">
                {{ application.late_reason }}
              </p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-divider v-if="application.reviewed_by" class="my-5"></v-divider>
        <v-row v-if="application.reviewed_by">
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-account-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  application.reviewed_by
                }}</v-list-item-title>
                <v-list-item-subtitle>Reviewed by</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-clock-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ application.reviewed_at }}
                </v-list-item-title>
                <v-list-item-subtitle>Reviewed at</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-information-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ application.review_status }}
                </v-list-item-title>
                <v-list-item-subtitle>Review Status</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12">
            <v-sheet class="px-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Review Remarks</v-sheet
              >
              <p class="text--primary">
                {{
                  application.review_remarks != "" &&
                  application.review_remarks != null
                    ? application.review_remarks
                    : "N/A"
                }}
              </p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        (auth.uuid == application.staff_uuid &&
          ($_checkPermission(
            auth.permissions,
            'Manage Leave Applications',
            'Delete'
          ) ||
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Update'
            )) &&
          application.review_status == 'PENDING') ||
        ($_checkPermission(
          auth.permissions,
          'Manage Leave Applications',
          'Update'
        ) &&
          $_isSubordinate(staff, auth.uuid, application.staff_uuid) &&
          application.review_status == 'PENDING')
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent"> Actions </v-sheet>
      <v-row v-if="loading">
        <v-col cols="12" v-for="i in 4" :key="i + 'vla'">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-list v-else color="actionList">
        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Update'
            ) &&
            auth.uuid == application.staff_uuid &&
            application.review_status == 'PENDING'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Edit Leave Application</v-list-item-title>
            <v-list-item-subtitle>
              Make chanes to the leave application.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn depressed color="primary" outlined @click="editItem()">
              <v-icon left> mdi-pencil </v-icon>
              Edit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Update'
            ) &&
            $_isSubordinate(staff, auth.uuid, application.staff_uuid) &&
            application.review_status == 'PENDING'
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Update'
            ) &&
            $_isSubordinate(staff, auth.uuid, application.staff_uuid) &&
            application.review_status == 'PENDING'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Reject Leave Application </v-list-item-title>
            <v-list-item-subtitle>
              Deny staff’s request for permission to edit the application entry.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="orange"
              outlined
              @click="rejectDialog = true"
            >
              <v-icon left> mdi-close </v-icon>
              Reject
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Update'
            ) &&
            $_isSubordinate(staff, auth.uuid, application.staff_uuid) &&
            application.review_status == 'PENDING'
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Update'
            ) &&
            $_isSubordinate(staff, auth.uuid, application.staff_uuid) &&
            application.review_status == 'PENDING'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Approve Leave Application</v-list-item-title>
            <v-list-item-subtitle>
              Approve a user’s request and provide them with permission to edit
              the application entry.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="updateReviewStatusX('APPROVED')"
              :loading="statusLoading"
            >
              <v-icon left> mdi-check </v-icon>
              Approve
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Delete'
            ) &&
            auth.uuid == application.staff_uuid &&
            application.review_status == 'PENDING'
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Leave Applications',
              'Delete'
            ) &&
            auth.uuid == application.staff_uuid &&
            application.review_status == 'PENDING'
          "
        >
          <v-list-item-content>
            <v-list-item-title> Delete Application Entry</v-list-item-title>
            <v-list-item-subtitle>
              Permanently remove the application entry from the IMSO database.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="deleteDialog = true"
            >
              <v-icon left>mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <!-- Delete application dialog -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this application? This action cannot
          be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteApplication()"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Remarks dialog -->
    <v-dialog v-model="rejectDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to reject this application?
          <v-textarea
            flat
            outlined
            label="Remarks"
            v-model="reviewRemarks"
            :rules="[rules.required]"
            class="mt-4"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="updateReviewStatusX('REJECTED')"
            :loading="statusLoading"
          >
            Reject
          </v-btn>
          <v-btn color="secondary" text @click="rejectDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Remarks dialog -->
    <v-dialog v-model="approveDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to approve this application?
          <v-textarea
            flat
            outlined
            label="Remarks"
            v-model="reviewRemarks"
            :rules="[rules.required]"
            class="mt-4"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="approveDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateReviewStatusX('APPROVED')"
            :loading="statusLoading"
          >
            Approve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
// import constants from "@/constants.json";
import {
  getLeaveApplication,
  updateReviewStatus,
  syncOfflineLeaveApplicationChanges,
  deleteLeaveApplication,
} from "@/services/leaveApplicationsService";
import { getMyLeaveApplication } from "@/services/myDataService";
import { fetchAndStoreStaffData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    rejectDialog: false,
    approveDialog: false,
    statusLoading: false,
    deleteLoading: false,
    deleteDialog: false,
    reviewRemarks: null,
    application: {},
    loading: false,
    staff: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  async created() {
    this.loading = true;
    this.staff = await fetchAndStoreStaffData();
    this.staff.forEach((person) => {
      person["name"] = person.first_name + " " + person.last_name;
    });
    if (this.$route.query.mylog && this.$route.query.mylog == "true") {
      await this.getMyApplication();
    } else {
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Leave Applications",
          "Read All"
        ) ||
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Leave Applications",
          "Read Sub"
        )
      ) {
        await this.getApplication();
      } else {
        this.goBack();
      }
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageMyLeaveApplicationList" });
    },
    editItem() {
      this.$router.push({
        name: "PageEditLeaveApplication",
        params: { uuid: this.application.uuid },
        query: { bc: this.application.id },
      });
    },
    async updateReviewStatusX(status) {
      if (this.reviewRemarks || status == "APPROVED") {
        this.statusLoading = true;
        let updateReviewStatusReturn = await updateReviewStatus({
          uuid: this.application.uuid,
          reviewed_by: this.auth.first_name + " " + this.auth.last_name,
          review_status: status,
          review_remarks: this.reviewRemarks,
        });
        this.statusLoading = false;
        if (updateReviewStatusReturn == "success") {
          this.rejectDialog = false;
          this.approveDialog = false;
          this.reviewRemarks = null;
          if (this.$route.query.mylog && this.$route.query.mylog == "true") {
            await this.getMyApplication();
          } else {
            if (
              this.$_checkPermission(
                this.auth.permissions,
                "Manage Leave Applications",
                "Read All"
              ) ||
              this.$_checkPermission(
                this.auth.permissions,
                "Manage Leave Applications",
                "Read Sub"
              )
            ) {
              await this.getApplication();
            }
          }
        }
      }
    },
    async getMyApplication() {
      this.loading = true;
      await syncOfflineLeaveApplicationChanges();
      this.application = await getMyLeaveApplication(this.$route.params.uuid);
      this.loading = false;
    },

    async getApplication() {
      this.loading = true;
      this.application = await getLeaveApplication(this.$route.params.uuid);
      this.loading = false;
    },

    async deleteApplication() {
      this.deleteLoading = true;
      let deleteReturn = await deleteLeaveApplication({
        uuid: this.application.uuid,
      });
      this.deleteLoading = false;
      if (deleteReturn == "success") {
        this.deleteDialog = false;
        this.goBack();
      }
    },
  },
};
</script>